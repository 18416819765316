<template>
  <div class="privacyPolicy-content">
    <div class='webdivContainer'>
      <!-- <web-div style="height:100px;" src="/static/pdf/shensixieyi.pdf"></web-div> -->
      <div class="title">
        <p>隐私政策</p>
        <p>第 2.0 版本</p>
        <p>更新日期：<strong>2024/09/06</strong></p>
        <p>生效日期：<strong>2024/04/01</strong></p>
      </div>
      <div class="body">
        <p>导言</p>
        <P>智慧客房保洁助手 是一款由 杭州钛比科技有限公司（以下简称” 我们“）提供的产品。 您在使用我们的服务时，我们可能会收集和使
          用您的相关信息。我们在此通过本《隐私政策》向您说明，在使用我
          们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们
          为您提供的访问、更新、控制和保护这些信息的方式。 本《隐私政
          策》与您所使用的 智慧客房保洁助手 服务息息相关，希望您仔细阅
          读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。
          本《隐私政策》中涉及的相关技术词汇，我们以简明扼要的表述，
          并提供进一步说明的链接，以便您的理解。</P>
        <strong>您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》
          收集、使用、储存和分享您的相关信息。</strong>
        <P>如对本《隐私政策》或相关适宜有任何问题，请通过
          <strong>0571-87755736</strong> 与我们联系。
        </P>
        <p>1. 我们收集的信息</p>
        <p>我们或我们的第三方合作伙伴提供服务时，可能会收集、储存和
          使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成
          为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服
          务拟达到的效果。</p>
        <ul>
          <li>
            <strong>个人信息</strong>，您在注册账户或使用我们的服务时，向我们提供的相
            关个人信息，个人信息包括电话号码、身份证号。电话号码用于账号注册，身份证号用于查询健康证信息。若您不需要健康证相关信息则不需要填写。

          </li>
          <li><strong>位置信息</strong>，指您开启设备定位功能并使用我们基于位置提供的相
            关服务时，收集的有关您位置的信息，包括：</li>
          <P>您通过具有定位功能的移动设备使用我们的服务时，通过
            GPS 或 WiFi 等方式收集的您的地理位置信息；</P>
          <p><b>目的：位置信息用于蓝牙搜索连接工作设备。</b><b>(操作步骤：首页-搜索设备，申请位置权限)</b></p>
          <P>您可以通过关闭定位功能，停止对您的地理位置信息的收集。</P>
          <li>
            <strong>日志信息</strong>，指您使用我们的服务时，系统可能通过
            cookies、标识符及相关技术收集的信息，包括您的<strong>设备信息、浏览信息、点击信息</strong>，并将该等信息储存为日志信息，确保服务安全。您可以通过浏览器设置拒绝或管理 cookie、
            标识符或相关技术的使用。
          </li>
          <li>
            <strong>设备信息</strong>，为了向你展示产品服务，我们会收集您的设备信息，包括设备名称、设备型号、MAC地址及IMEI、设备识别码、移动应用安装列表、蓝牙地址硬件特征信息、操作系统和应用程序版本、分辨率、服务提供商网络ID（PLMN）来对相应的产品服务内容进行适配以适应您的设备界面。<br />
          </li>
          <li><strong>通讯录短信信息</strong>，为了进行账户的成功注册登陆，我们会收集您的短信验证码信息进行登陆注册。</li>
          <li><strong>蓝牙权限</strong>，你可以选择开启系统的蓝牙权限，通过蓝牙连接RFID蓝牙读卡器设备，用于酒店保洁卫生工作记录。您可以在系统权限中关闭此项功能，一旦关闭您将无法使用上述功能。</li>
          <li>
            <strong>基于相机的拓展功能</strong>，您可以选择开启系统相机权限.<br />
            <p>目的：使用相机扫描条形码进行4G读卡器设备连接，用于酒店保洁卫生工作记录<b>（操作步骤：首页-扫一扫，申请相机权限）</b>。您可以在系统权限中关闭此项功能，一旦关闭您将无法使用上述功能。</p>
          </li>

          <li>
            我们的产品基于DCouduni-app(S+App/MapApp)开发，应用运行期间需要收集您的设备唯一识别码(IMEI/ndroidID/DEVICE_ID/IDFA、SIM卡IMSI信息、OAID)以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。
          </li>
        </Ul>
        <p>2. 信息的存储</p>
        <p>2.1 信息存储的方式和期限</p>
        <ul>
          <li>
            我们会通过安全的方式存储您的信息，包括本地存储（例如利用
            APP 进行数据缓存）、数据库和服务器日志。
          </li>
          <li>
            一般情况下，我们只会在为实现服务目的所必需的时间内或法律
            法规规定的条件下存储您的个人信息。
          </li>
        </ul>
        <p>2.2 信息存储的地域</p>
        <ul>
          <li>
            我们会按照法律法规规定，将境内收集的用户个人信息存储于中
            国境内。
          </li>
          <li>
            目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输
            或存储的，我们会向您告知信息出境的目的、接收方、安全保证
            措施和安全风险，并征得您的同意。
          </li>
        </ul>
        <p>2.3 产品或服务停止运营时的通知</p>
        <ul>
          <li>
            当我们的产品或服务发生停止运营的情况时，我们将以推送通知、
            公告等形式通知您，并在合理期限内删除您的个人信息或进行匿
            名化处理，法律法规另有规定的除外。
          </li>
        </ul>
        <p>3. 信息安全</p>
        <p>我们使用各种安全技术和程序，以防信息的丢失、不当使用、未
          经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例
          如 SSL）来保护您提供的个人信息。</p>
        <p>4. 我们如何使用信息</p>
        <p>我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</p>
        <ul>
          <li>
            向您提供服务；
          </li>
          <li>
            在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗
            监测、存档和备份用途，确保我们向您提供的产品和服务的安全
            性；
          </li>
          <li>
            帮助我们设计新服务，改善我们现有服务；
          </li>
          <!-- <li>
            使我们更加了解您如何接入和使用我们的服务，从而针对性地回
            应您的个性化需求，例如语言设定、位置设定、个性化的帮助服
            务和指示，或对您和其他用户作出其他方面的回应
          </li> -->
          <li>
            向您提供与您更加相关的广告以替代普遍投放的广告；
          </li>
          <li>
            评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；
          </li>
          <li>
            软件认证或管理软件升级；
          </li>
          <li>
            让您参与有关我们产品和服务的调查。
          </li>
        </ul>
        <p>5. 信息共享</p>
        <p>目前，我们不会主动共享或转让您的个人信息至第三方，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至第三方情形时，我们会直接或确认第三方征得您对上述行为的明示同意。</p>
        <p>为了投放广告，评估、优化广告投放效果等目的，我们需要向广
          告主及其代理商等第三方合作伙伴共享您的部分数据，要求其严格遵守我们关于数据隐私保护的措施与要求，包括但不限于根据数据保护
          协议、承诺书及相关数据处理政策进行处理，避免识别出个人身份，
          保障隐私安全。</p>
        <p>我们不会向合作伙伴分享可用于识别您个人身份的信息（例如您
          的姓名或电子邮件地址），除非您明确授权。</p>
        <p>我们不会对外公开披露所收集的个人信息，如必须公开披露时，
          我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的
          敏感信息，并征得您的明示同意。</p>
        <p>随着我们业务的持续发展，我们有可能进行合并、收购、资产转
          让等交易，我们将告知您相关情形，按照法律法规及不低于本《隐私
          政策》所要求的标准继续保护或要求新的控制者继续保护您的个人信
          息。</p>
        <p>另外，根据相关法律法规及国家标准，以下情形中，我们可能会
          共享、转让、公开披露个人信息无需事先征得您的授权同意：</p>
        <ul>
          <li>
            与国家安全、国防安全直接相关的
          </li>
          <li>
            与公共安全、公共卫生、重大公共利益直接相关的；
          </li>
          <li>
            犯罪侦查、起诉、审判和判决执行等直接相关的；
          </li>
          <li>
            出于维护个人信息主体或其他个人的生命、财产等重大合法权益
            但又很难得到本人同意的；
          </li>
          <li>
            个人信息主体自行向社会公众公开个人信息的；
          </li>
          <li>
            从合法公开披露的信息中收集个人信息的，如合法的新闻报道、
            政府信息公开等渠道。
          </li>
        </ul>
        <p>6. 您的权利</p>
        <p>在您使用我们的服务期间，我们会视产品具体情况为您提供
          相应的操作设置，以便您可以查询、删除、更正或撤回您的相关个人
          信息，您可参考相应的具体指引进行操作。此外，我们还设置了投诉
          举报渠道，您的意见将会得到及时的处理。如果您无法通过上述途径
          和方式行使您的个人信息主体权利，您可以通过本《隐私政策》中提
          供的联系方式提出您的请求，我们会按照法律法规的规定予以反馈。</p>
        <p>当您决定不再使用我们的产品或服务时，可以申请注销账户。在<b>“我的-设置-账号与安全-注销账号”</b>中查看注销流程或进行申请注销账号。</p>
        <p>在您主动注销账号后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的个人信息。</p>
        <!-- <p>当您决定不再使用我们的产品或服务时，可以申请注销账户。注
          销账户后，除法律法规另有规定外，我们将删除或匿名化处理您的个
          人信息。</p> -->
        <!-- <p>7. 注销</p>
        <ul>
          <li>
           <b>注销申请</b> 如需注销,请联系客服人员,客服电话：0571-87755736
          </li>
          <li>
            <b>注销完成时间</b> 客户提出注销申请后,在1-5个工作日完成注销
          </li>
          <li>
            <b>注销条件告知</b> ①账号注销后,该账号下所有工作记录数据清除 ②账户如仍有保洁分，注销视为自动放弃积分。
          </li>
        </ul> -->
        <p>7. 变更</p>
        <p>我们可能适时修订本《隐私政策》的条款。当变更发生时，我们
          会在版本更新时向您提示新的《隐私政策》，并向您说明生效日期。
          请您仔细阅读变更后的《隐私政策》内容，<strong>若您继续使用我们的服务，
            即表示您同意我们按照更新后的《隐私政策》处理您的个人信息。</strong></p>
        <p>8. 未成年人保护</p>
        <p>我们鼓励父母或监护人指导未满十八岁的未成年人使用我们的
          服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和
          指导。</p>
        <p>9.本隐私政策的适用范围</p>
        <p>本《隐私政策》适用于智慧客房保洁助手App和智慧客房保洁助手微信小程序的用户。</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  created() {
  },
  mounted() { },
  methods: {
    goback() {
    },
  },
};
</script>

<style lang="scss" scoped>
.privacyPolicy-content {
  width: 100%;
  padding: 15px;
  font-size: 14px;
  box-sizing: border-box;

  .webdivContainer {
    padding: 30rpx;
    padding-bottom: 40rpx;
    box-sizing: border-box;

    .title {
      text-align: center;

      h1 {
        font-size: 24px;
        font-weight: bold;
      }

      p {
        text-indent: 2em;
        text-align: center;
        margin-top: 20px;
        word-break: break-word;
        overflow-wrap: break-word;
        line-height: 24px;
      }
    }

    .body {
      margin-top: 15px;

      p {
        font-size: 14px;
        text-indent: 2em;
        text-align: left;
        margin-top: 20px;
        word-break: break-word;
        overflow-wrap: break-word;
        line-height: 24px;
      }

      ul {
        li {
          color: #333;
          list-style-type: disc;
          list-style-position: inside;
          // font-weight: 600;
          font-size: 14px;
          text-indent: 2em;
          text-align: left;
          word-break: break-word;
          overflow-wrap: break-word;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
